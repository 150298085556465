import React, { Component, RefObject } from 'react';
import { Unsubscribe } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { highlightedContainer } from '../../features/searchmarkcontainer/SearchMarkSelector';
import { filterDocumentStore } from '../../redux/filter-document';
import { FilterDocumentState, ViewOnlyCategory } from '../../redux/filter-document/filter-document.state';
import store from '../../redux/store';
import { defaultTableItem } from '../../redux/tables';
import { addTableData } from '../../redux/tables/actions';
import { ComponentArg } from '../../types/component-arg';
import { JsonNodeType } from '../../types/json-node.type';
import { key } from '../util/key';
import { Colgroup } from './colgroup';
import { TableCaption, TableCaptionWithButton } from './table-caption';
import { Tbody } from './tbody';
import { Thead } from './thead';
import { RootState } from '../../redux/reducer';

const mapStateToProps = (state: RootState) => ({
   areAllExpanded: state.ExpandCollapseState.areAllExpanded,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export interface TableProps extends ComponentArg, PropsFromRedux {}

export interface TableState {
    caption?: JsonNodeType;
    thead?: JsonNodeType;
    tbody?: JsonNodeType;
    cols?: JsonNodeType[];
    isTableVisible: boolean;
    showTableInModal: boolean;
    isTableFilterApplied: boolean;
}

class Table extends Component<TableProps, TableState> {
    private storeUpdatedUnsubscribe: Unsubscribe = () => false;
    
    constructor( p: TableProps ) {
        super( p );
        this.state = {
            isTableVisible: p.areAllExpanded,
            showTableInModal: false,
            isTableFilterApplied: false
        };
        this.tableRef = React.createRef();
    }

    private readonly tableRef: RefObject<HTMLTableElement> | null;

    componentDidMount() {
        this.loadTableParts( this.props );
        this.storeUpdatedUnsubscribe = store.subscribe(this.storeUpdated.bind( this ));
    }

    componentWillUnmount() {
        this.storeUpdatedUnsubscribe();
    }

    componentDidUpdate(prevProps: TableProps) {
        if (prevProps.areAllExpanded !== this.props.areAllExpanded) {
            this.setState({ isTableVisible: this.props.areAllExpanded });
        }
    }

    storeUpdated() {
        const { tbody } = this.state;

        if (tbody) {
            const state = store.getState();
            const highLightedParents = highlightedContainer(state, tbody.id);
            
            if (highLightedParents) {
                const { isTableVisible } = this.state; 
                if (!isTableVisible) {
                    this.setState({isTableVisible: true });
                }
            }
        }
    }

    loadTableParts( arg: ComponentArg ) {
        if ( !arg || !arg.node || !arg.node.c || !arg.node.c.length ) {
            return;
        }

        let caption: JsonNodeType | undefined = undefined;
        let thead: JsonNodeType | undefined = undefined;
        let tbody: JsonNodeType | undefined = undefined;
        let cols: JsonNodeType[] = [];

        for ( const child of arg.node.c ) {
            if (!child.x) {
                continue;
            }
            const childTag = child.x!.tag;
            if ( childTag === 'caption' ) {
                caption = child.x!;
            } else if ( childTag === 'col' ) {
                cols.push( child.x! );
            } else if ( childTag === 'thead' ) {
                thead = child.x!;
            } else if ( childTag === 'tbody' ) {
                tbody = child.x!;
            }
        }

        const htmlTableElement = this.tableRef!.current;

        if ( htmlTableElement ) {
            this.setTableProperty( htmlTableElement, 'width', arg );
            this.setTableProperty( htmlTableElement, 'border', arg );
            this.setTableProperty( htmlTableElement, 'rules', arg );
            this.setTableProperty( htmlTableElement, 'style', arg );
        }

        this.setState( {
            caption: caption,
            thead: thead,
            tbody: tbody,
            cols: cols
        } );
    }

    setTableProperty( htmlTableElement: HTMLTableElement, propertyName: string, arg: ComponentArg ) {
        const propertyValue = arg!.node!.a![ propertyName ];
        if ( propertyValue ) {
            htmlTableElement.setAttribute( propertyName, propertyValue );
        }
    }

    handleTableVisibleToggle() {
        this.setState({isTableVisible: !this.state.isTableVisible });
    }

    closeDialog = () => {
        this.setState({showTableInModal: false})
    }

    render() {

        let isTableFilterApplied = false;
        const storeState: FilterDocumentState = filterDocumentStore.getState();
        if ( storeState.filterIsApplied && storeState.viewOnlyCategory === ViewOnlyCategory.tables) {
            isTableFilterApplied = true;
        }

        const {t, node} = this.props;
        let  captionElement = null;
        const isInsideRequirement = node && node.a['is-inside-requirement'] ? true : false;
        const tableHasCaption = Boolean(this.state.caption);
        let hideTable = isInsideRequirement && !this.state.isTableVisible;

        if (isInsideRequirement) {
            captionElement = tableHasCaption ? TableCaptionWithButton({
                node: this.state.caption,
                t,
                onClick: this.handleTableVisibleToggle.bind(this),
                ariaExpanded: this.state.isTableVisible,
                tableId: key( this.props )
            }) : null;

            hideTable = tableHasCaption ? hideTable : false;
        }
        else {
            captionElement = tableHasCaption
                ? TableCaption({ node: this.state.caption!, t })
                : null;
        }


        const colGroup = this.state.cols && this.state.cols.length ? Colgroup( { cols: this.state.cols! } ) : null,
            thead = this.state.thead ? Thead( { node: this.state.thead!, t } ) : null,
            tbody = this.state.tbody ? Tbody( { node: this.state.tbody!, t } ) : null;

        const table = (
            <table
                ref={this.tableRef}
                key={key( this.props )}
                id={`#${key( this.props )}`}
            >
                {captionElement}
                {colGroup}
                {thead}
                {tbody}
            </table>
        );

        let hidden = isInsideRequirement && !this.state.isTableVisible;
        if (isTableFilterApplied) {
            hidden = false;
        }
        const tableitem = defaultTableItem();
        tableitem.isShowTableInDialogVisisble = hidden;
        tableitem.tableId = key( this.props );
        addTableData(tableitem);

        return (
            <div className={`sts-table-internal-wrap ${hideTable ? 'collapsed' : ''}`}>
                {table}
            </div>
        );
    }
}

const ConnectedTable = connector(Table);

export { ConnectedTable as Table };
