import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../redux/reducer'
import { Comment, UserCommentTeam } from '../../../redux/types';
import { CommentingFrameworkState, CommentingMode } from './CommentingFrameworkState';

const commentingFrameworkState = (state: RootState) => state.CommentingFrameworkState;

const selectedCommentingMode = createSelector(
  commentingFrameworkState,
  (state: CommentingFrameworkState): CommentingMode | undefined => state.commentingMode,
);

const submittedCommentCount = createSelector(
  commentingFrameworkState,
  (state: CommentingFrameworkState): number => state.comments.length
);

const submittedComments = createSelector(
  commentingFrameworkState,
  (state: CommentingFrameworkState): Comment[] => state.comments
);

const selectedComment = createSelector(
  commentingFrameworkState,
  (state: CommentingFrameworkState): Comment | undefined => state.selectedComment
);

const userCommentTeams = createSelector(
  commentingFrameworkState,
  (state: CommentingFrameworkState): UserCommentTeam[] => state.userCommentTeams
);

export {
  selectedCommentingMode,
  submittedCommentCount,
  submittedComments,
  selectedComment,
  userCommentTeams
}
