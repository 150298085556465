import { isEqual } from 'lodash';

import { GraphService } from '../../graph/graph.service';
import { AboveBelow, UpdateSectionService } from './update-section.service';
import { sectionVisibleSubject } from '../../redux/identity/sectionVisibleSubject';
import { tocVisibleSubject } from '../../redux/identity/tocVisibleSubject';
import { updateSectionsSubject } from '../../redux/identity/updateSectionsSubject';
import { highlightToCSubject } from '../../redux/identity/highlightToCSubject';
import { NavigationType, SectionSelection, ViewportNotification } from '../../types/lazy.type';
import { promptScrollToIdSubject } from '../../redux/identity/promptScrollToIdSubject';
import { ThrottleActionType } from '../../redux/identity/throttleSaga';
import { debugSectionsRendered, scrollDelay } from '../../constants/json-node';
import { getActiveLazyState } from '../../redux/standard-state/lazyStateProxy';
import { NodeId } from '../../types/json-node.type';
import { ErrorTypes } from '../../redux/show-error';
import { ShowErrorInstance } from '../../singleton';
import { getClosestParentSectionById, getClosestRequirementById, getClosestFigureById, getClosestTableById, getClosestPartialEnquiryById } from './getClosestParentSectionById';
import { nodes2Indices } from './nodes2Indices';
import { filterDocumentStore } from '../../redux/filter-document';
import { ViewOnlyCategory } from '../../redux/filter-document/filter-document.state';
import { CurrentFilteredCategory, IsFilterApplied } from '../../redux/filter-document/filter-document.selector';

export class TocService {
  constructor(
    private updateSectionService: UpdateSectionService,
    private graphService: GraphService
  ) {
    sectionVisibleSubject.subscribe(this.onSectionVisibleChange.bind(this));
    tocVisibleSubject.subscribe(this.onToCVisibleChange.bind(this));
  }

  selectByIdTimeout?: any;

  selectById(selection: SectionSelection): void {
    const state = getActiveLazyState(),
      currentSelection: SectionSelection | undefined = state.tocSelection,
      filterIsApplied = filterDocumentStore.getState().filterIsApplied;

    if (currentSelection && !isEqual(currentSelection, selection)) {
      this.onTocSelect(selection, false, filterIsApplied).then();
    } else {
      this.onTocSelect(selection, true, filterIsApplied).then();
    }
  }

  /**    the caller should handle user's multiple clicks    */
  private async onTocSelect(selection: SectionSelection, findSection: boolean, filterIsApplied: boolean): Promise<void> {
    let internalSelection = selection;
    this.selectByIdTimeout = setTimeout(this.onIncompleteTocSelect.bind(this), scrollDelay * 2);

    const state = getActiveLazyState(),
      updateService = this.updateSectionService;

    let idx = state.allLazyLoadedIdsIndices.get(internalSelection.sectionNodeId);

    if (idx === undefined) {
      idx = state.allLazyLoadedIdsIndices.get(internalSelection.navigateIdAfterLoadSection!);
    }

    if (idx === undefined) {
      if (findSection) {
        const updatedSelection = await TocService.tryFindSection(selection, IsFilterApplied(), CurrentFilteredCategory());

        if (updatedSelection !== null) {
          internalSelection = updatedSelection;
          idx = state.allLazyLoadedIdsIndices.get(internalSelection.sectionNodeId);

          if (idx === undefined) {
            idx = state.allLazyLoadedIdsIndices.get(internalSelection.navigateIdAfterLoadSection!);
          }
        }
      }

      if (idx === undefined) {
        const element = document.getElementById(internalSelection.sectionNodeId);
        if (!element) {
          if (!filterIsApplied) {
            console.log('Section not found. Section id: ' + internalSelection.sectionNodeId);
            ShowErrorInstance.Fn(ErrorTypes.UrlNotFound);
          }
        }
        this.onIncompleteTocSelect();
        return;
      }
    }

    state.navigationType = NavigationType.tocSelect;
    state.tocSelection = internalSelection;

    state.firstRenderedIdx = idx;
    state.lastRenderedIdx = idx;
    updateService.expandSections(AboveBelow.above);
    updateService.expandSections(AboveBelow.below);
    TocService.notifyUpdateSections();
  }

  private static async tryFindSection(selection: SectionSelection, isFilterApplied: boolean, viewOnlyCategory: ViewOnlyCategory | undefined): Promise<SectionSelection | null> {

    if (!isFilterApplied) {
      const sectionId: NodeId | null = await getClosestParentSectionById(selection.sectionNodeId);

      if (sectionId) {
        const result: SectionSelection = {
          navigateIdAfterLoadSection: sectionId,
          sectionNodeId: sectionId,
        }

        return result;
      }
    }

    const viewCategory = viewOnlyCategory!;

    if (selection.navigateIdAfterLoadSection) {
      let sectionId: NodeId | null = null;
      if (viewCategory === ViewOnlyCategory.requirements || viewCategory === ViewOnlyCategory.themes) {
        sectionId = await getClosestRequirementById(selection.navigateIdAfterLoadSection);
      }

      if (viewCategory === ViewOnlyCategory.tables) {
        sectionId = await getClosestTableById(selection.navigateIdAfterLoadSection);
      }

      if (viewCategory === ViewOnlyCategory.figures) {
        sectionId = await getClosestFigureById(selection.navigateIdAfterLoadSection);
      }

      if (viewCategory === ViewOnlyCategory.commentingEnabled) {
        sectionId = await getClosestPartialEnquiryById(selection.navigateIdAfterLoadSection);
      }

      if (sectionId) {
        const result: SectionSelection = {
          navigateIdAfterLoadSection: sectionId,
          sectionNodeId: selection.sectionNodeId,
        }

        return result;
      }
    }

    return null;
  }

  private onIncompleteTocSelect() {
    const state = getActiveLazyState(),
      selection: SectionSelection | undefined = state.tocSelection;

    if (selection) {
      console.error(`selectById to ${JSON.stringify(selection)} did not complete`);
    }

    this.completeToCSelect();
  }

  completeToCSelect(): void {
    clearTimeout(this.selectByIdTimeout);

    const state = getActiveLazyState();
    if (!state.tocSelection) {
      return;
    }
    const nextSelection = state.tocSelectionNext;
    if (nextSelection) {
      state.tocSelectionNext = undefined;
      this.selectById(nextSelection);
    } else {
      const navigateTo = state.tocSelection.navigateIdAfterLoadSection;
      if (navigateTo) {
        promptScrollToIdSubject.dispatch({
          type: null,
          content: navigateTo
        });
      }
      state.tocSelection = undefined;
    }
  }

  documentLoad(): void {
    const state = getActiveLazyState(),
      updateService = this.updateSectionService;

    state.firstRenderedIdx = 0;
    state.lastRenderedIdx = 0;
    updateService.expandSections(AboveBelow.below);
    TocService.notifyUpdateSections();

    this.highlight1stSection();
  }

  private highlight1stSection(): void {
    const state = getActiveLazyState(),
      nodeId = state.idx2NodeId(0);

    if (!nodeId) return;

    state.visibleToC.add(nodeId);
    state.visibleSections.add(nodeId);
    this.notifyToChighlight();
  }

  static get tocSelectionByUser(): NodeId | undefined {
    const selection: SectionSelection | undefined = getActiveLazyState().tocSelection;
    return selection ? selection.sectionNodeId : undefined;
  }

  get tocHighlightFromVisibleSection(): NodeId | undefined {
    const state = getActiveLazyState();
    const predicate = (id: NodeId) => state.visibleSections.has(id),
      firstVisibleSection: NodeId | undefined = state.allLazyLoadedIdsInDisplayOrder.find(predicate);

    if (firstVisibleSection === undefined) {
      return undefined;
    }

    if (state.visibleToC.has(firstVisibleSection)) {
      return firstVisibleSection;
    }

    const minMaxIdx: [number, number] = nodes2Indices(state.visibleSections, getActiveLazyState()),
      minIdx = minMaxIdx[0];

    let lastVisibleToC: NodeId = firstVisibleSection;
    state.allLazyLoadedIdsInDisplayOrder.forEach((id: NodeId, idx: number) => {
      if (idx <= minIdx && state.visibleToC.has(id)) {
        lastVisibleToC = id;
      }
    });

    return lastVisibleToC;
  }

  onToCVisibleChange(): void {
    const notification: ViewportNotification | undefined = tocVisibleSubject.getState().content;
    if (!notification) return;

    const state = getActiveLazyState(),
      visible: boolean = notification.visible,
      nodeId = notification.nodeId;

    if (visible) {
      state.visibleToC.add(nodeId);
    } else {
      state.visibleToC.delete(nodeId);
    }

    this.notifyToChighlight();
  }

  onSectionVisibleChange(): void {
    const notification: ViewportNotification | undefined = sectionVisibleSubject.getState().content;
    if (!notification) return;

    this.debugSectionVisibleChanged(notification);

    const state = getActiveLazyState(),
      nodeId: NodeId = notification.nodeId,
      visible: boolean = notification.visible;

    if (visible) {
      state.visibleSections.add(nodeId);
    } else {
      state.visibleSections.delete(nodeId);
    }

    this.notifyToChighlight();
  }

  private debugSectionVisibleChanged(notification: ViewportNotification) {
    if (!debugSectionsRendered) return;

    const graphService = this.graphService,
      title: string | undefined = graphService.nodeId2Title.call(graphService, notification.nodeId),
      color = notification.visible ? 'green' : 'maroon';

    console.debug(`%c${title}`, `color:${color}`);
  }

  static notifyUpdateSections(): void {
    updateSectionsSubject.dispatch({ type: null, content: null });
  }

  private notifyToChighlight() {
    const highlight = this.tocHighlightFromVisibleSection;
    if (highlight !== undefined) {
      highlightToCSubject.dispatch({ type: ThrottleActionType.raw, content: highlight });
    }
  }
}
